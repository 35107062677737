import { Component } from 'react';
import { request } from '../../assets/js/fun';
import config from '../../config';

export default class ApiNotasFiscais extends Component {

    static async get(params, filtros = "") {
        return new Promise(async (sucesso, rejeicao) => {
            let url = config.api.corsAnywhere.baseUrl + config.api.bling.baseUrl;
            url += `/notasfiscais/page=${params.pagina}/json/`;
            url += `?apikey=${localStorage.getItem("chaveApiBling")}`;
            url += `&filters=${filtros}`;

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            request(url, requestOptions).then(async (response) => {
                let notasfiscais = response.retorno.notasfiscais;
                if (notasfiscais) {
                    notasfiscais.sort((a, b) => {
                        return parseInt(b.notafiscal.numero) - parseInt(a.notafiscal.numero);
                    });
                }
                sucesso(notasfiscais);
            }).catch(error => {
                rejeicao(error);
            });

        });
    }


}
