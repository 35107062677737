

import { Component } from 'react';
import { request } from '../../assets/js/fun';
import config from '../../config';

export default class ApiPedidos extends Component {

    static async get(params, filtros = "") {
        return new Promise(async (sucesso, rejeicao) => {
            let url = config.api.corsAnywhere.baseUrl + config.api.bling.baseUrl;
            url += `/pedidos/page=${params.pagina}/json/`;
            url += `?apikey=${localStorage.getItem("chaveApiBling")}`;
            url += `&historico=true`;
            url += `&filters=${filtros}`;

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            request(url, requestOptions).then(async (response) => {
                let pedidos = response.retorno.pedidos;
                if (pedidos) {
                    pedidos.sort((a, b) => {
                        return parseInt(b.pedido.numero) - parseInt(a.pedido.numero);
                    });
                }
                sucesso(pedidos);
            }).catch(error => {
                rejeicao(error);
            });

        });
    }


    static async getByID(params) {
        return new Promise(async (sucesso, rejeicao) => {
            let url = config.api.corsAnywhere.baseUrl + config.api.bling.baseUrl;
            url += `/pedido/${params.numero}/json/`;
            url += `?apikey=${localStorage.getItem("chaveApiBling")}`;
            url += `&historico=true`;

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            request(url, requestOptions).then(async (response) => {
                let pedido = response?.retorno?.pedidos?.[0]?.pedido;
                sucesso(pedido);
            }).catch(error => {
                rejeicao(error);
            });

        });
    }

    static async put(params) {
        return new Promise(async (sucesso, rejeicao) => {
            let xml = `
            <?xml version="1.0" encoding="UTF-8"?>
            <pedido>
                <idSituacao>${params.idSituacao}</idSituacao>
            </pedido>
            `;

            let url = config.api.corsAnywhere.baseUrl + config.api.bling.baseUrl;
            url += `/pedido/${params.numero}/json/`;
            url += `?apikey=${localStorage.getItem("chaveApiBling")}`;
            url += `&xml=${encodeURIComponent(xml)}`;

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var requestOptions = {
                method: 'PUT',
                headers: myHeaders,
                redirect: 'follow'
            };

            request(url, requestOptions).then(async (response) => {
                let pedidos = response.retorno.pedidos;
                if (pedidos) {
                    pedidos.sort((a, b) => {
                        return parseInt(b.pedido.numero) - parseInt(a.pedido.numero);
                    });
                }
                sucesso(pedidos);
            }).catch(error => {
                rejeicao(error);
            });

        });
    }


    static async getEstatisticas(ano, mes) {
        return new Promise(async (sucesso, rejeicao) => {
            var data = new Date();
            data = new Date(ano, (mes - 1), 1);
            var primeiroDia = new Date(data.getFullYear(), data.getMonth(), 1);
            var ultimoDia = new Date(data.getFullYear(), data.getMonth() + 1, 0);

            let url = config.api.corsAnywhere.baseUrl + config.api.bling.baseUrl;
            url += `/pedidos/page=1/json/`;
            url += `?apikey=${localStorage.getItem("chaveApiBling")}`;
            url += `&historico=true`;
            url += `&filters=dataEmissao[${String(primeiroDia.getDate()).padStart(2, "0")}/${String((primeiroDia.getMonth()) + 1).padStart(2, "0")}/${primeiroDia.getFullYear()} TO ${String(ultimoDia.getDate()).padStart(2, "0")}/${String((ultimoDia.getMonth()) + 1).padStart(2, "0")}/${ultimoDia.getFullYear()}]; idSituacao[6,9,29977]`;

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            request(url, requestOptions).then(async (response) => {
                if (response.retorno.pedidos) {
                    let pedidos = response.retorno.pedidos;
                    let resposta = {
                        numeroPedidos: pedidos.length,
                        valorPedidos: 0,
                        valorDescontoPedidos: 0,
                        quantidadeProdutos: 0
                    }
                    pedidos.forEach(i => {
                        resposta.valorPedidos += parseFloat(i?.pedido?.totalvenda);
                        resposta.valorDescontoPedidos += parseFloat(i?.pedido?.desconto);
                        i.pedido.itens.forEach(i2 => {
                            resposta.quantidadeProdutos += parseInt(i2?.item?.quantidade);
                        });
                    });
                    sucesso(resposta);
                } else {
                    if (response.retorno.erros[0].erro.msg) {
                        if(String(response.retorno.erros[0].erro.msg).includes("A informacao desejada nao foi encontrada")){
                            sucesso({
                                numeroPedidos: 0,
                                valorPedidos: 0,
                                valorDescontoPedidos: 0,
                                quantidadeProdutos: 0
                            });
                        }else{
                        rejeicao(response.retorno.erros[0].erro.msg);
                        }
                    } else {
                        rejeicao(response.retorno);
                    }
                }
            }).catch(async error => {
                console.error(error);
                rejeicao(error);
            });

        });
    }

}
