import { Component } from 'react';
import { request } from '../../assets/js/fun';
import Cookies from 'js-cookie';
import config from '../../config';

export default class ApiUser extends Component {

    static async logar(data) {
        return new Promise(async (sucesso, rejeicao) => {
            ApiUser.verificarChaveApi(data).then(async (response) => {
                localStorage.setItem('chaveApiBling', data.chaveApi);
                Cookies.set("userLogado", true, { expires: 1, path: '' });
                sucesso(response);
            }).catch(error => {
                rejeicao(error);
            });

        });
    }

    static async verificarChaveApi(data) {
        return new Promise(async (sucesso, rejeicao) => {
            let url = config.api.corsAnywhere.baseUrl + config.api.bling.baseUrl;
            url += '/pedidos/page=1/json/';
            url += `?apikey=${data.chaveApi}`;

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            request(url, requestOptions).then(response => {
                sucesso(response);
            }).catch(error => {
                rejeicao(error);
            });

        });
    }

    static async getDataUserLogado(fLogado = () => { }, fDeslogado = () => { }) {
        return new Promise(async (sucesso, rejeicao) => {
            let chaveApi = null;
            try {
                chaveApi = localStorage.getItem('chaveApiBling');
            } catch (error) { }
            if (chaveApi) {
                ApiUser.verificarChaveApi({ chaveApi }).then(async (res) => {
                    await Cookies.set("userLogado", true, { expires: 1, path: '' });
                    fLogado();
                    setTimeout(() => {
                        sucesso(chaveApi);
                    }, 500);
                }).catch(async (error) => {
                    console.error("ServicesUserLogin.get() -> ", error);
                    fDeslogado();
                    setTimeout(() => {
                        sucesso("User deslogado", error);
                    }, 500);
                });
            } else {
                console.error("ServicesUserLogin.get() -> ", "Chave Api null");
                fDeslogado();
                setTimeout(() => {
                    sucesso("User deslogado");
                }, 500);
            }
        });
    }

}
