import { Component } from 'react';
import html from './produtos.jsx';
import { browserHistory } from 'react-router'
import ApiUser from '../../../api/user.js';
import ApiProdutos from '../../../api/produtos';


export default class PagePainelProdutos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      json: null,
      erro: null,
      isLoaded: false
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);

    let userLogadoInfo = await ApiUser.getDataUserLogado(() => { }, () => { browserHistory.push('/logar') });
    let produtos = await ApiProdutos.get({pagina: 1}, "situacao[A]; tipo[P]");

    await this.setState({
      isLoaded: true,
      userLogadoInfo,
      produtos
    });
  }

  render() {
    return html(this);
  }
}

