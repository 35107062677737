import React from 'react';
import LayoutError from '../../../layout/objetos/error/error';
import LayoutCarregando from '../../../layout/objetos/carregando/carregando';
import LayoutObjetosPanelNotasFiscais from '../../../layout/objetos/panelNotasFiscais/panelNotasFiscais';

export default function html(shelf) {

    if (shelf.state.erro != null) {
        return (<LayoutError erro={shelf.state.erro}></LayoutError>);
    } else if (!shelf.state.isLoaded) {
        return (<LayoutCarregando></LayoutCarregando>);
    } else {

        // API carregada com sucesso pagina oficial
        return (
            <div className="container text-center animated fadeIn slow">
                <h1 className="remove-mobile ">{localStorage.getItem("pageName")}</h1>
                <br className="remove-mobile" />

                <div className="notasFiscais">

                    {
                        shelf.state?.notasFiscais?.length > 1 ? (<>
                            {
                                shelf.state.notasFiscais.map(i => (
                                    <LayoutObjetosPanelNotasFiscais
                                        key={"notafiscal-"+i.notafiscal.numero}
                                        notafiscal={i.notafiscal}
                                    />
                                ))
                            }
                        </>) : (<>
                            <h1>Sem notas fiscais criadas no Bling.</h1>
                        </>)
                    }


                </div>
            </div>
        )

    }
}