import React, { Component } from 'react';

export default class LayoutCarregando extends Component {

  render() {
    return (
      <div className="page-carregando animated fadeIn slow" style={this.props.style}>
        <center>
          <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
          <h3>Carregando...</h3>
        </center>
      </div>
    )
  }
}
