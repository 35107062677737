import { Component } from 'react';
import { request } from '../../assets/js/fun';
import config from '../../config';

export default class ApiBrasil extends Component {

    static async get(params) {
        return new Promise(async (sucesso, rejeicao) => {
            let url = config.api.corsAnywhere.baseUrl + config.api.brasilApi.baseUrl;
            url += `/cep/v1/${params.cep}`;

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            request(url, requestOptions).then(async (response) => {
                sucesso(response);
            }).catch(error => {
                sucesso(error);
            });

        });
    }


}
