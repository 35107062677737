import React, { Component } from 'react';
import LayoutMenu from '../../objetos/menu/menu';

export default class LayoutModeloMenu extends Component {

  componentDidMount(){
    }

  render() {
    let pathName = this.props.location.pathname;
    let espaco = "";
    if (pathName !== "/") {
      espaco = "navbar-espaco-top";
    }
    return (
      <div>
        <LayoutMenu props={this.props} />
        <div className={espaco}></div>
        {this.props.children}
        <div className="navbar-espaco-bottom"></div>
      </div>
    )
  }
}

