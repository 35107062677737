import React from 'react';
import LayoutError from '../../../layout/objetos/error/error';
import LayoutCarregando from '../../../layout/objetos/carregando/carregando';
import LayoutObjetosPanelProdutos from '../../../layout/objetos/panelProdutos/panelProdutos';

export default function html(shelf) {

    if (shelf.state.erro != null) {
        return (<LayoutError erro={shelf.state.erro}></LayoutError>);
    } else if (!shelf.state.isLoaded) {
        return (<LayoutCarregando></LayoutCarregando>);
    } else {

        // API carregada com sucesso pagina oficial
        return (
            <div className="container text-center animated fadeIn slow">
                <h1 className="remove-mobile ">{localStorage.getItem("pageName")}</h1>
                <br className="remove-mobile" />

                <div className="produtos">

                    {
                        shelf.state?.produtos?.length > 1 ? (<>
                            {
                                shelf.state.produtos.map(i => (
                                    <LayoutObjetosPanelProdutos
                                        key={"produto-"+i.produto.id}
                                        produto={i.produto}
                                    />
                                ))
                            }
                        </>) : (<>
                            <h1>Sem produtos cadastro no Bling.</h1>
                        </>)
                    }


                </div>
            </div>
        )

    }
}