import React from 'react';
import LayoutError from '../../../layout/objetos/error/error';
import LayoutCarregando from '../../../layout/objetos/carregando/carregando';
import LayoutObjetosPanel from '../../../layout/objetos/panel/panel';
import config from '../../../../config';

export default function html(shelf) {

    if (shelf.state.erro != null) {
        return (<LayoutError erro={shelf.state.erro}></LayoutError>);
    } else if (!shelf.state.isLoaded) {
        return (<LayoutCarregando></LayoutCarregando>);
    } else {

        // API carregada com sucesso pagina oficial
        return (
            <div className="container text-center animated fadeIn slow">
                <h1 className="remove-mobile ">{localStorage.getItem("pageName")}</h1>
                <br className="remove-mobile" />


                <LayoutObjetosPanel className="text-left">
                    <h2>Dados do Pedido: </h2>

                    <div className="col-xs-12">
                        <span className="label label-default pull-right" style={{ backgroundColor: shelf.state.situacaoDados?.cor }} >
                            {shelf.state.pedido?.situacao}
                        </span>
                        <div className="panelPedido">
                            <span className="numero">Nº: <b>#{shelf.state.pedido?.numero}</b></span><br />
                            {
                                shelf.state.linkPedidoLoja ? (<>
                                    {
                                        String(shelf.state.pedido?.tipoIntegracao).toLowerCase() === "skyhub" ? (
                                            <a href={(config?.multiLojas?.linkPedido?.["b2w"]).replace("[ID]", (shelf.state.pedido?.numeroPedidoLoja).split("-")[1])} title="Clique para ver o pedido na multiloja" target="_blank" rel="noopener noreferrer">
                                                <span className="integracao">Loja: <b>{shelf.state.pedido?.tipoIntegracao}</b></span><br />
                                                <span className="numeroMarketplace">Loja nº: <b>#{shelf.state.pedido?.numeroPedidoLoja}</b></span><br />
                                            </a>
                                        ) : (
                                            <a href={shelf.state.linkPedidoLoja.replace("[ID]", shelf.state.pedido?.numeroPedidoLoja)} title="Clique para ver o pedido na multiloja" target="_blank" rel="noopener noreferrer">
                                                <span className="integracao">Loja: <b>{shelf.state.pedido?.tipoIntegracao}</b></span><br />
                                                <span className="numeroMarketplace">Loja nº: <b>#{shelf.state.pedido?.numeroPedidoLoja}</b></span><br />
                                            </a>
                                        )
                                    }
                                </>) : (<>
                                    <span className="integracao">Loja: <b>{shelf.state.pedido?.tipoIntegracao}</b></span><br />
                                    <span className="numeroMarketplace">Loja nº: <b>#{shelf.state.pedido?.numeroPedidoLoja}</b></span><br />
                                </>)
                            }
                            <span>Data: <b>{shelf.state.pedidoData?.dia}/{shelf.state.pedidoData?.mes}/{shelf.state.pedidoData?.ano}</b></span>
                        </div>
                    </div>



                    <div className="col-xs-12">
                        <div className="panelPedido">
                            {shelf.state.pedido?.observacoes && (<>
                                <span className="observacoes">Observação: <b>{shelf.state.pedido?.observacoes}</b></span><br />
                            </>)}
                            {shelf.state.pedido?.observacaointerna && (<>
                                <span className="observacaointerna">Observação interna: <b>{shelf.state.pedido?.observacaointerna}</b></span><br />
                            </>)}
                        </div>
                    </div>

                </LayoutObjetosPanel>


                <LayoutObjetosPanel className="text-left">
                    <h2>Produtos: </h2>

                    <table className="table">
                        <thead>
                            {
                                shelf.state.pedido?.itens.map(i => (
                                    <tr>
                                        <td className="imgTabelaItens"><img src={i?.item?.imagem} className="imgProduto img-responsive" alt={`Foto do produto`}></img></td>
                                        <td>{parseFloat(i?.item?.quantidade)} {i?.item?.un}</td>
                                        <td>{i?.item?.descricao} <br /> SKU: {i?.item?.codigo}</td>
                                        <td><b>{parseFloat(i?.item?.valorunidade).toLocaleString('pt-br', { style: "currency", currency: "BRL" })}</b></td>
                                    </tr>
                                ))
                            }

                            {
                                (parseFloat(shelf.state.pedido?.desconto) !== 0) && (
                                    <tr className="text-right">
                                        <td></td>
                                        <td></td>
                                        <td>Desconto:</td>
                                        <td>{parseFloat(shelf.state.pedido?.desconto).toLocaleString('pt-br', { style: "currency", currency: "BRL" })}</td>
                                    </tr>
                                )
                            }
                            <tr className="text-right">
                                <td></td>
                                <td></td>
                                <td><b>Valor total:</b></td>
                                <td><b>{parseFloat(shelf.state.pedido?.totalvenda).toLocaleString('pt-br', { style: "currency", currency: "BRL" })}</b></td>
                            </tr>
                        </thead>
                    </table>
                </LayoutObjetosPanel>


                <LayoutObjetosPanel className="text-left">
                    <h2>Cliente: </h2>

                    <div className="panelPedido">
                        <span className="nome">Nome: <b>{shelf.state.pedido?.cliente?.nome}</b></span><br />
                        {shelf.state.pedido?.cliente?.cpf && (<>
                            <span className="cpf">CPF: <b>{shelf.state.pedido?.cliente?.cpf}</b></span><br />
                        </>)}
                        {shelf.state.pedido?.cliente?.rg && (<>
                            <span className="rg">RG: <b>{shelf.state.pedido?.cliente?.rg}</b></span><br />
                        </>)}
                        {shelf.state.pedido?.cliente?.cnpj && (<>
                            <span className="cnpj">CNPJ: <b>{shelf.state.pedido?.cliente?.cnpj}</b></span><br />
                        </>)}
                        {shelf.state.pedido?.cliente?.email && (<>
                            <span className="phone">Email: <a href={`mailto:${shelf.state.pedido?.cliente?.email}`}><b>{shelf.state.pedido?.cliente?.email}</b></a></span><br />
                        </>)}
                        <span className="cidade">Cidade: <b>{shelf.state.pedido?.cliente?.cidade}</b></span><br />
                        {shelf.state.pedido?.cliente?.fone && (<>
                            <span className="phone">Celular: <a href={`tel:+${shelf.state.pedido?.cliente?.fone}`}><b>+{shelf.state.pedido?.cliente?.fone} </b></a> &nbsp;&nbsp;
                            <a href={`https://api.whatsapp.com/send?phone=${shelf.state.pedido?.cliente?.fone}`} target="_blank "rel="noopener noreferrer"><i className="fab fa-whatsapp"></i> </a> 
                            </span><br />
                        </>)}
                    </div>
                </LayoutObjetosPanel>


                <LayoutObjetosPanel className="text-left">
                    <h2>Transporte: </h2>

                    <div className="panelPedido">
                        <span className="nome">Nome: <b>{shelf.state.pedido?.transporte?.enderecoEntrega?.nome}</b></span><br />
                        <span className="endereco">Endereço: <b>{shelf.state.pedido?.transporte?.enderecoEntrega?.endereco}, {shelf.state.pedido?.transporte?.enderecoEntrega?.numero} - {shelf.state.pedido?.transporte?.enderecoEntrega?.bairro} - {shelf.state.pedido?.transporte?.enderecoEntrega?.cidade} - {shelf.state.pedido?.transporte?.enderecoEntrega?.uf} {shelf.state.pedido?.transporte?.enderecoEntrega?.cep}</b>&nbsp;&nbsp;
                        { (shelf.state.consultaCEP?.cep) ? (<>
                                <b className="text-success">CEP Válido <i className="fad fa-shield-check"></i></b>
                        </>) : (
                                <b className="text-danger">(ATENÇÃO: O CEP consta como inválido, converse com o seu cliente para conferir)</b>
                            )}
                        </span><br />
                        {shelf.state.pedido?.transporte?.enderecoEntrega?.complemento && (<>
                            <span className="rg">Complemento: <b>{shelf.state.pedido?.transporte?.enderecoEntrega?.complemento}</b></span><br />
                        </>)}

                        <center>
                            <br/><br/>
                        <form class="card show-mais-acessados" action="https://www2.correios.com.br/sistemas/rastreamento/ctrl/ctrlRastreamento.cfm" method="post" target="_blank">
                            <div class="card-txt">
                                <h4>Rastrei o objeto pelo correios:</h4>
                            </div>
                            <div class="campo">
                                <input name="P_LINGUA" value="001" type="hidden"/>
                                <input name="P_TIPO" value="001" type="hidden"/>
                                <input type="text" name="objetos" id="objetos" size="18" maxlength="18" placeholder="Digite o código de rastreio." className="form-control form-inline-auto"/>
                                <button type="submit" className="btn btn-default"><i className="fas fa-external-link"></i></button>
                            </div>
                        </form>
                        </center>
                    </div>

                    <div className="text-right">
                        <hr />
                        <span><b>Valor do frete: {parseFloat(shelf.state.pedido?.valorfrete).toLocaleString('pt-br', { style: "currency", currency: "BRL" })}</b></span>
                    </div>
                </LayoutObjetosPanel>


                <LayoutObjetosPanel className="text-left">
                    <h2>Parcelamento: </h2>

                    <table className="table">
                        <thead>
                            {
                                shelf.state.pedido?.parcelas?.map(i => (
                                    <tr>
                                        <td>{i?.parcela?.dataVencimento?.split("-")?.[2]?.split(" ")?.[0]}/{i?.parcela?.dataVencimento?.split("-")?.[1]}/{i?.parcela?.dataVencimento?.split("-")?.[0]}</td>
                                        <td>{i?.parcela?.forma_pagamento?.descricao}</td>
                                        <td><b>{parseFloat(i?.parcela?.valor).toLocaleString('pt-br', { style: "currency", currency: "BRL" })}</b></td>
                                        <td>{i?.parcela?.obs}</td>
                                    </tr>
                                ))
                            }
                        </thead>
                    </table>
                </LayoutObjetosPanel>


                {
                    shelf.state.pedido?.ocorrencias?.length > 1 && (
                        <LayoutObjetosPanel className="text-left">
                            <h2>Ocorrências: </h2>

                            <table className="table">
                                <thead>
                                    {
                                        shelf.state.pedido?.ocorrencias?.map(i => (
                                            <tr>
                                                <td>{i?.ocorrencia?.data?.split("-")?.[2]?.split(" ")?.[0]}/{i?.ocorrencia?.data?.split("-")?.[1]}/{i?.ocorrencia?.data?.split("-")?.[0]}</td>
                                                <td>{i?.ocorrencia?.ocorrencia}</td>
                                                <td>{i?.ocorrencia?.situacao}</td>
                                            </tr>
                                        ))
                                    }
                                </thead>
                            </table>
                        </LayoutObjetosPanel>
                    )
                }


            </div>
        )

    }
}