import { Component } from 'react';
import html from './inicio.jsx';
import { browserHistory } from 'react-router'
import ApiUser from '../../api/user.js';
import ApiPedidos from '../../api/pedidos.js';


export default class PagePainelInicio extends Component {
  constructor(props) {
    super(props);
    this.setMesAtualDados = this.setMesAtualDados.bind(this, this);
    this.state = {
      json: null,
      erro: null,
      isLoaded: false,
      todosFiltros: "display-none",
      situacaoSelecionada: "Em aberto"
    };
  }

  async componentDidMount() {
    try {
      window.scrollTo(0, 0);
  
      let mesAnoDados = `${(new Date().getFullYear())}-${String((new Date().getMonth())+1).padStart(2, '0')}`;
      let mesDados = String(mesAnoDados).split("-")[1];
      let anoDados = String(mesAnoDados).split("-")[0];
      let userLogadoInfo = await ApiUser.getDataUserLogado(() => { }, () => { browserHistory.push('/') });
      let estatisticas = await ApiPedidos.getEstatisticas(anoDados, mesDados);

      await this.setState({
        isLoaded: true,
        userLogadoInfo,
        estatisticas,
        mesAnoDados
      });

    } catch (error) {
      await this.setState({
        isLoaded: true,
        erro: error
      });
    }
  }

  async setMesAtualDados(shelf, shelfHtml) {
    shelfHtml.preventDefault();
    let mesAnoDados = shelfHtml.target.value;
    let mesDados = String(mesAnoDados).split("-")[1];
    let anoDados = String(mesAnoDados).split("-")[0];

    try {

      await shelf.setState({ isLoaded: false });
      let estatisticas = await ApiPedidos.getEstatisticas(anoDados, mesDados);
      await shelf.setState({
        isLoaded: true,
        estatisticas,
        mesAnoDados
      });

    } catch (error) {

      await shelf.setState({
        isLoaded: true,
        erro: error
      });

    }
  }

  render() {
    return html(this);
  }
}

