import { Component } from 'react';
import { request } from '../../assets/js/fun';
import config from '../../config';

export default class ApiProdutos extends Component {

    static async get(params, filtros = "") {
        return new Promise(async (sucesso, rejeicao) => {
            let url = config.api.corsAnywhere.baseUrl + config.api.bling.baseUrl;
            url += `/produtos/page=${params.pagina}/json/`;
            url += `?apikey=${localStorage.getItem("chaveApiBling")}`;
            url += `&estoque=S`;
            url += `&imagem=S`;
            url += `&filters=${filtros}`;

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            request(url, requestOptions).then(async (response) => {
                let produtos = response?.retorno?.produtos;
                sucesso(produtos);
            }).catch(error => {
                rejeicao(error);
            });

        });
    }

    static async getByCodigo(params) {
        return new Promise(async (sucesso, rejeicao) => {
            let url = config.api.corsAnywhere.baseUrl + config.api.bling.baseUrl;
            url += `/produto/${params.codigo}/json/`;
            url += `?apikey=${localStorage.getItem("chaveApiBling")}`;
            url += `&estoque=S`;
            url += `&imagem=S`;

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            request(url, requestOptions).then(async (response) => {
                let produtos = response?.retorno?.produtos?.[0];
                sucesso(produtos);
            }).catch(error => {
                rejeicao(error);
            });

        });
    }

}
