import React, { Component } from 'react';
import { Link } from 'react-router';
import * as Fun from '../../../../assets/js/fun';
import Cookies from 'js-cookie';
import $ from 'jquery';
import LayoutCarregando from '../carregando/carregando';

export default class LayoutMenuLateral extends Component {

  constructor(props) {
    super(props);
    this.state = {
      json: null,
      erro: null,
      isLoaded: false
    };
  }

  openNav() {
    document.getElementById("menu-mySidenav").style.width = "250px";
    $('.menu-sidenav').addClass("menu-sidenav-open");
    $('#menu-mySidenav-backdrop').addClass("menu-backdrop");
  }

  closeClicBlack() {
    $("#menu-mySidenav-backdrop,.menu-sidenav a,.menu-sidenav .closebtn").on("click", function () {
      document.getElementById("menu-mySidenav").style.width = "0";
      $('.menu-sidenav').removeClass("menu-sidenav-open");
      $('#menu-mySidenav-backdrop').removeClass("menu-backdrop");
    });
  }

  async componentDidMount() {
    let userLogado = await Cookies.get("userLogado");

    await this.setState({ userLogado, isLoaded: true });
    this.closeClicBlack();
  }

  render() {
    if (!this.state.isLoaded) {
      return (<LayoutCarregando></LayoutCarregando>);
    } else {
      return (
        <div>
          <div id="menu-mySidenav" className="menu-sidenav">
            <img className="menu-foto" src="/assets/img/logotipo_branca_pc.png" alt="Logotipo com o wallpaper do aplicativo." />
            <Link to="/painel">
              <i className="fal fa-home " aria-hidden="true"></i><span>Início</span>
            </Link>

            {
              (String(this.state.userLogado) === "true") ? (<>

                <Link to="/painel/pedidos">
                  <i className="fal fa-truck-loading" aria-hidden="true"></i><span>Pedidos</span>
                </Link>

                <Link to="/painel/notas/fiscais">
                  <i className="fal fa-receipt" aria-hidden="true"></i><span>Notas Fiscais</span>
                </Link>

                <Link to="/painel/produtos">
                  <i className="fal fa-box-full" aria-hidden="true"></i><span>Produtos</span>
                </Link>

                {/* eslint-disable-next-line */}
                <a onClick={() => { Fun.abrirNotificao("Essa parte do sistema ainda não está pronta.", "") }}>
                  <i className="fal fa-user-circle" aria-hidden="true"></i><span>Conta</span>
                </a>

                <a href="/" onClick={Fun.sairDaConta}>
                  <i className="fal fa-power-off " aria-hidden="true"></i><span>Sair</span>
                </a>

              </>) : (<>

                <Link to="/logar">
                  <i className="fal fa-sign-in" aria-hidden="true"></i><span>Logar-se</span>
                </Link>

              </>)
            }


            <button className="closebtn">
              <i className="fal fa-chevron-left" aria-hidden="true"></i>
            </button>
          </div >
          <div id="menu-mySidenav-backdrop" />
        </div>
      )
    }
  }
}

