import React, { Component } from 'react';
import { Link } from 'react-router';
import Cookies from 'js-cookie';
import * as Fun from '../../../../../assets/js/fun';
import LayoutCarregando from '../../carregando/carregando';

export default class LayoutMenuDesktopCima extends Component {

  constructor(props) {
    super(props);
    this.state = {
      json: null,
      erro: null,
      isLoaded: false
    };
  }


  async componentDidMount() {
    let userLogado = await Cookies.get("userLogado");

    await this.setState({ userLogado, isLoaded: true });
  }

  render() {
    if (!this.state.isLoaded) {
      return (<LayoutCarregando></LayoutCarregando>);
    } else {
      return (
        <div id="navbar" className="navbar-collapse" remove-mobile="">
          <ul className="nav navbar-right center">

            <li>
              <Link to="/painel">
                <i className="fal fa-home " aria-hidden="true"></i><span>Início</span>
              </Link>
            </li>

            {
              (String(this.state.userLogado) === "true") ? (<>

                <li>
                  <Link to="/painel/pedidos">
                    <i className="fal fa-truck-loading" aria-hidden="true"></i><span>Pedidos</span>
                  </Link>
                </li>

                <li>
                  <Link to="/painel/notas/fiscais">
                    <i className="fal fa-receipt" aria-hidden="true"></i><span>Notas Fiscais</span>
                  </Link>
                </li>

                <li>
                  <Link to="/painel/produtos">
                    <i className="fal fa-box-full" aria-hidden="true"></i><span>Produtos</span>
                  </Link>
                </li>

                <li>
                  {/* eslint-disable-next-line */}
                  <a onClick={() => { Fun.abrirNotificao("Essa parte do sistema ainda não está pronta.", "") }}>
                    <i className="fal fa-user-circle" aria-hidden="true"></i><span>Conta</span>
                  </a>
                </li>

                <li className={"sair "}>
                  <a href="/" onClick={Fun.sairDaConta}>
                    <i className="fal fa-power-off" aria-hidden="true"></i><span>Sair</span>
                  </a>
                </li>

              </>) : (<>

                <li className="separar"> | </li>
                <li>
                  <Link to="/logar">
                    <i className="fal fa-sign-in" aria-hidden="true"></i><span>Logar-se</span>
                  </Link>
                </li>

              </>)
            }


          </ul>
        </div>
      )
    }
  }
}

