import { Component } from 'react';
import html from './logar.jsx';
import ApiUser from '../../api/user.js';

export default class PageLogar extends Component {
  constructor(props) {
    super(props);
    this.formSubmit = this.formSubmit.bind(this, this);
    this.state = {
      json: null,
      erro: null,
      isLoaded: false
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    await ApiUser.getDataUserLogado(() => { window.location.href = '/painel' });
    await this.setState({
      isLoaded: true
    });
  }

  formSubmit(shelf, e) {
    shelf.setState({ isLoaded: false, erro: null });
    e.preventDefault();
    let data = {};
    let formData = new FormData(e.target);
    formData.forEach((valor, chave) => {
      data[chave] = valor;
    });

    ApiUser.logar(data).then(function (res) {
      window.location.href = '/painel';
    }).catch(function (error) {
      if (error?.retorno?.erros?.erro?.cod === 3) {
        shelf.setState({ erro: "Chave API do Bling Inválida" });
      } else {
        shelf.setState({ erro: "" + error });
      }
      return;
    });

  }

  render() {
    return html(this);
  }
}

