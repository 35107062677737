import React, { Component } from 'react';

export default class LayoutError extends Component {

  render() {
    return (
      <div className="page-error animated fadeIn slow container-fluid" style={this.props.style}>
        <center>
          <i className="animated infinite tada fa fa-times fa-3x"></i>
          <h3>{this.props.erro}</h3>
          <br/>
          <button onClick={()=>{window.location.reload()}} className="btn btn-vipmodels btn-lg btn-block btn-block-mobile" > Tentar novovamente! </button>
        </center>
      </div>
    )
  }
}

