import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { Router, Route, Redirect, browserHistory, IndexRoute } from 'react-router';

import './index.css';
import $ from 'jquery';

import LayoutModeloMenu from './app/layout/modelos/menu/menu';
import LayoutModeloVazio from './app/layout/modelos/vazio/vazio';
import Page404 from './app/pages/404/404';
import PageHome from './app/pages/home/home';
import PageLogar from './app/pages/logar/logar';
import PagePainelPedidos from './app/pages/painel/pedidos/pedidos';
import PagePainelPedido from './app/pages/painel/pedido/pedido';
import PagePainelInicio from './app/pages/painel/inicio';
import PagePainelProdutos from './app/pages/painel/produtos/produtos';
import PagePainelNotasFiscais from './app/pages/painel/notasFiscais/notasFiscais';

window.jQuery = $;
window.$ = $;
global.jQuery = $;
require('bootstrap');

const pUrl = process.env.PUBLIC_URL;
const rootElement = document.getElementById('root');
const rootRouters = (
    <Router history={browserHistory}>
        <Route path={pUrl + '/'} component={LayoutModeloVazio}>
            <IndexRoute name='Início' component={PageHome} />
        </Route>
        <Route path={pUrl + '/'} component={LayoutModeloMenu}>
            <Route path={pUrl + '/logar'} name='Logar' component={PageLogar} />
            <Route path={pUrl + '/painel'} name='Painel' component={PagePainelInicio} />
            <Route path={pUrl + '/painel/pedidos'} name='Pedidos' component={PagePainelPedidos} />
            <Route path={pUrl + '/painel/produtos'} name='Produtos' component={PagePainelProdutos} />
            <Route path={pUrl + '/painel/pedido/:numeroPedido'} name='Detalhes do pedido' component={PagePainelPedido} />
            <Route path={pUrl + '/painel/notas/fiscais'} name='Notas fiscais' component={PagePainelNotasFiscais} />
            <Route path={pUrl + '/tutorial/bling/chave/api'} name='Tutorial chave API Bling ' component={PageHome} />
        </Route>
        <Route path={pUrl + '/'} component={LayoutModeloVazio}>
            <Route path={pUrl + '/404'} name='Erro 404' component={Page404} />
            <Redirect from={pUrl + '/*'} to="/404" />
        </Route>
    </Router>
);
if (rootElement.hasChildNodes()) {
    ReactDOM.hydrate(rootRouters, rootElement);
} else {
    ReactDOM.render(rootRouters, rootElement);
}

// ServicesUserLogin.prototype.verificar();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
