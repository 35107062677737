import React from 'react';
import LayoutError from '../../layout/objetos/error/error';
import LayoutCarregando from '../../layout/objetos/carregando/carregando';

export default function html(shelf) {

    if (shelf.state.erro != null) {
        return (<LayoutError erro={shelf.state.erro}></LayoutError>);
    } else if (!shelf.state.isLoaded) {
        return (<LayoutCarregando></LayoutCarregando>);
    } else {

        // API carregada com sucesso pagina oficial
        return (
            <div className="tela-home">

                <br /><br />
                <center>
                    <a href="/">
                        <img src="/assets/img/logotipo_branca_pc.png" alt="Logotipo StyleBlinging" />
                    </a>
                </center>
                <br /><br />

                <div className="container">
                    <div id="carousel-tutorial-home" className="carousel slide tutorial-carousel" data-ride="carousel">
                        <ol className="carousel-indicators">
                            <li data-target="#carousel-tutorial-home" data-slide-to="0" className="active"></li>
                            <li data-target="#carousel-tutorial-home" data-slide-to="1"></li>
                            <li data-target="#carousel-tutorial-home" data-slide-to="2"></li>
                        </ol>

                        <div className="carousel-inner" role="listbox">

                            <div className="item active">
                                <center>
                                    <div className="col-xs-12 col-md-6">
                                        <img src="/assets/svg/undraw_welcome_3gvl.svg" width="50%" alt="Ilustração." />
                                    </div>
                                    <div className="col-xs-12 col-md-6">
                                        <h1>Bem vindo(a)</h1>
                                        <p>StyleBlinging é um aplicativo que soluciona o problema de interface e usabilidade do Bling ERP pelo celular, o aplicativo oficial não tinha a usabilidade que minha equipe precisava por isso eu desenvolvi o StyleBlinging, e agora estou disponibilizando a todos vocês, espero que gostem.</p>
                                        <p>Nas próximas telas vou mostrar como você pode usar o StyleBlinging.</p>
                                    </div>
                                </center>
                            </div>

                            <div className="item">
                                <center>
                                    <div className="col-xs-12 col-md-6">
                                        <img src="/assets/svg/undraw_progressive_app_m9ms.svg" width="50%" alt="Ilustração." />
                                    </div>
                                    <div className="col-xs-12 col-md-6">
                                        <h1>App integrado com Bling</h1>
                                        <p>Nosso app é integrado 100% com o serviço Bling, por esse motivo você já deve ter uma conta no site <a href="www.bling.com.br" target="_blank" >www.bling.com.br</a> para usar nosso app.</p>
                                        <p>Nas próximas telas vou mostrar como você encontra a chave da API do Bling, para conseguir integrar com o StyleBlinging.</p>
                                    </div>
                                </center>
                            </div>

                            <div className="item">
                                <center>
                                    <div className="col-xs-12 col-md-8" align="left">
                                        <h1>Integração - Criando usuário API</h1>
                                        <p>Para usar no app, você terá que criar um usuário API na sua conta Bling siga esses passos:</p>
                                        <p><b>1º:</b> Acesse sua conta Bling.</p>
                                        <p><b>2º:</b> Vá em Preferências depois Sistemas e depois Usuário e Usuarios API.</p>
                                        <p><b>3º:</b> Na lateral direita clique no botão INCLUIR USUÁRIO.</p>
                                        <p><b>4º:</b> Escolha o tipo USUÁRIO API.</p>
                                        <p><b>5º:</b> Preencha o campo Nome com "StyleBlinging", campo Email com "styleblinging@styleblinging.com.br" e na opção API key clique em GERAR.</p>
                                        <p><b>6º:</b> Em ppermissões, escolha o que a nosso aplicativo terá acesso.</p>
                                        <p><b>7º:</b> Recomendamos marcar todas as opções para que o aplicativo funcione corretamente.</p>
                                        <p><b>8º:</b> Clique em Salvar.</p>
                                        <p><b>9º:</b> Entre no usuário que acabou de criar.</p>
                                        <p><b>10º:</b> Copie a chave API gerada.</p>
                                        <p><b>11º:</b> Cole a chave API na tela de Logar do nosso aplicativo StyleBlinging.</p>
                                    </div>
                                    <div className="col-xs-12 col-md-4">
                                        <img src="/assets/TutorialApiBling.gif" width="80%" alt="GIF do tutorial Bling como adicionar usuário API." />
                                    </div>
                                </center>
                            </div>

                        </div>

                        <br /><br />
                        <div className="carousel-btns center">
                            <a className="btn btn-styleblinging btn-lg btn-next" href="#carousel-tutorial-home" role="button" data-slide="next">
                                Próximo
                    </a>
                        </div>

                    </div>

                </div>

            </div>
        )

    }
}