import React from 'react';
import LayoutError from '../../layout/objetos/error/error';
import LayoutCarregando from '../../layout/objetos/carregando/carregando';

export default function html(shelf) {

    if (shelf.state.erro != null) {
        return (<LayoutError erro={shelf.state.erro}></LayoutError>);
    } else if (!shelf.state.isLoaded) {
        return (<LayoutCarregando></LayoutCarregando>);
    } else {

        // API carregada com sucesso pagina oficial
        return (
            <div className="animated fadeIn slow">

                <div className="separacao-laranja text-center">
                    <div className="col-md-6 col-md-offset-3">
                        <h1 className="remove-mobile ">{localStorage.getItem("pageName")}</h1>

                        <div>
                            <br />
                            <form onSubmit={shelf.formSubmit} className="form-horizontal" autoComplete="off">
                                <div className="form-group">
                                    <label className="col-sm-2 control-label">* Chave API Bling</label>
                                    <div className="col-sm-10">
                                        <input name="chaveApi" id="chaveApi" type="text" className="form-control" placeholder="Cole aqui a chave API Bling criada na sua conta Bling." required="required" autoComplete="off" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="container-fluid">
                                        <button type="submit" className="btn btn-vipmodels btn-lg btn-block btn-block-mobile">
                                            <i className="fal fa-sign-in"></i>
                                                        Logar-se
                                                    </button>
                                        <hr />
                                    </div>
                                </div>
                            </form>

                            <div className="form-group">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <span>Onde encontro minha chave API Bling?</span><br /><br remove-mobile="" />
                                            <a href="/tutorial/bling/chave/api" className="btn btn-default btn-lg btn-block btn-block-mobile">
                                                <i className="fal fa-key"></i>
                                                    Tutorial chave API Bling
                                            </a>
                                            <br />
                                        </div>
                                        <div className="col-md-6">
                                            <span>Você ainda não tem uma conta?</span><br /><br remove-mobile="" />
                                            <a href="https://www.bling.com.br/inscricao" target="_blank" rel="noopener noreferrer" className="btn btn-default btn-lg btn-block btn-block-mobile">
                                                <i className="fal fa-user-plus"></i>
                                                    Criar conta Bling
                                            </a>
                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
        )

    }
}