import React from 'react';
import LayoutError from '../error/error';
import LayoutCarregando from '../carregando/carregando';
import { Component } from 'react';
import LayoutObjetosPanel from '../panel/panel';
import { Link } from 'react-router';
import ApiPedidos from '../../../api/pedidos';
import * as Fun from '../../../../assets/js/fun';
import $ from 'jquery';
import config from '../../../../config';

export default class LayoutObjetosPanelPedidos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            json: null,
            erro: null,
            isLoaded: false
        };
    }

    async componentDidMount() {
        let pedidoData = String(this.props.pedido.data).split("-");

        await this.setState({
            isLoaded: true,
            pedidoData: {
                ano: pedidoData[0],
                mes: pedidoData[1],
                dia: pedidoData[2]
            },
            situacaoDados: this.situacaoDados(this.props.pedido?.situacao),
            linkPedidoLoja: config?.multiLojas?.linkPedido?.[String(this.props.pedido?.tipoIntegracao).toLowerCase()]
        });
    }

    situacaoDados(situacaoPedido) {
        let situacaoDados = this.props.situacoes;
        situacaoDados = situacaoDados.filter(function (e) {
            return String(e.situacao?.nome) === String(situacaoPedido);
        });
        situacaoDados = situacaoDados[0]?.situacao;
        return situacaoDados;
    }

    atualizarSituacao(numero, idSituacao) {
        let situacaoNome = $(`.situacao-${numero} [value="${idSituacao}"]`).text();
        this.setState({
            isLoaded: false
        })
        ApiPedidos.put({ numero, idSituacao }).then(res => {
            Fun.abrirNotificao("Situação atualizada", "A situação do pedido foi atualizada no bling.");
            this.setState({
                isLoaded: true,
                situacaoDados: this.situacaoDados(situacaoNome)
            });
            $(`.situacao-${numero}`).val(idSituacao);
        }).catch(erro => {
            this.setState({
                isLoaded: true,
                erro: `${erro}`
            })
        })
    }

    render() {
        if (this.state.erro != null) {
            return (<LayoutObjetosPanel className="text-left"><LayoutError erro={this.state.erro} style={{ marginTop: "0px" }}></LayoutError></LayoutObjetosPanel>);
        } else if (!this.state.isLoaded) {
            return (<LayoutObjetosPanel className="text-left"><LayoutCarregando style={{ marginTop: "0px" }}></LayoutCarregando></LayoutObjetosPanel>);
        } else {
            return (
                <LayoutObjetosPanel className="text-left" key={this.props.key}>
                    <div className="row text-left panelPedido">
                        <div className="col-xs-3 col-sm-1">
                            <span className="numero"><b>#{this.props.pedido?.numero}</b></span><br />
                            {
                                this.state.linkPedidoLoja ? (<>
                                    {
                                        String(this.props.pedido?.tipoIntegracao).toLowerCase() === "skyhub" ? (
                                            <a href={(config?.multiLojas?.linkPedido?.["b2w"]).replace("[ID]", (this.props.pedido?.numeroPedidoLoja).split("-")[1])} title="Clique para ver o pedido na multiloja" target="_blank" rel="noopener noreferrer">
                                                <span className="integracao"><b>{this.props.pedido?.tipoIntegracao}</b></span><br />
                                            </a>
                                        ) : (
                                            <a href={this.state.linkPedidoLoja.replace("[ID]", this.props.pedido?.numeroPedidoLoja)} title="Clique para ver o pedido na multiloja" target="_blank" rel="noopener noreferrer">
                                                <span className="integracao"><b>{this.props.pedido?.tipoIntegracao}</b></span><br />
                                            </a>
                                        )
                                    }
                                </>) : (<>
                                    <span className="integracao"><b>{this.props.pedido?.tipoIntegracao}</b></span><br />
                                </>)
                            }
                            <span className="data"><b>{this.state.pedidoData?.dia}/{this.state.pedidoData?.mes}/{this.state.pedidoData?.ano}</b></span>
                        </div>
                        <Link to={`/painel/pedido/${this.props.pedido.numero}`} target="_blank" title="Clique para ver os detalhes desse pedido">
                            <div className="col-xs-9 col-sm-3">
                                <span><b>{this.props.pedido?.cliente?.nome}</b></span><br remove-mobile="true" />
                                <span remove-mobile="true"><b>Cidade:</b> {this.props.pedido?.cliente?.cidade} ({this.props.pedido?.cliente?.uf})</span><br remove-mobile="true" />
                            </div>
                            <div className="col-xs-9 col-sm-3">
                                <span>{this.props.pedido?.itens[0]?.item?.descricao}</span><br />
                                <span><b>Qtd:</b> {parseInt(this.props.pedido?.itens[0]?.item?.quantidade)}</span><br />
                                {
                                    this.props.pedido?.itens[1]?.item && (
                                        <span className="text-danger"><b>Existe mais itens nesse pedido...</b></span>
                                    )
                                }
                            </div>
                            <div className="col-xs-12" remove-pc="true"></div>
                            <div className="col-xs-6 col-sm-2">
                                <span className="numero"><b>{parseFloat(this.props.pedido?.totalvenda).toLocaleString('pt-br', { style: "currency", currency: "BRL" })}</b></span>
                                {
                                    this.props?.pedido?.parcelas && (<>
                                        <br /><span className="integracao">{this.props?.pedido?.parcelas[0]?.parcela?.forma_pagamento?.descricao}</span>
                                    </>)
                                }
                            </div>
                        </Link>
                        <div className="col-xs-6 col-sm-3">
                            <select
                                className={`form-control situacao situacao-${this.props.pedido?.numero}`}
                                onChange={(e) => { this.atualizarSituacao(this.props.pedido?.numero, e.target.value) }}
                                style={{ borderBottom: `0.5px solid ${this.state.situacaoDados?.cor}`, color: `${this.state.situacaoDados?.cor}`, cursor: "pointer" }}
                                title="Clique e selecione uma opção para alterar a situação do pedido"
                            >
                                {
                                    this.props.situacoes.map(situacao => (<>
                                        {
                                            situacao?.situacao?.nome === this.props.pedido?.situacao ? (
                                                <option value={situacao?.situacao?.id} selected disabled>{situacao?.situacao?.nome}</option>
                                            ) : (
                                                <option value={situacao?.situacao?.id}>{situacao?.situacao?.nome}</option>
                                            )
                                        }
                                    </>))
                                }
                            </select>
                        </div>
                    </div>
                </LayoutObjetosPanel>
            )
        }
    }
}