import { Component } from 'react';
import html from './notasFiscais.jsx';
import { browserHistory } from 'react-router'
import ApiUser from '../../../api/user.js';
import ApiNotasFiscais from '../../../api/notasFiscais.js';


export default class PagePainelNotasFiscais extends Component {
  constructor(props) {
    super(props);
    this.state = {
      json: null,
      erro: null,
      isLoaded: false
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);

    let userLogadoInfo = await ApiUser.getDataUserLogado(() => { }, () => { browserHistory.push('/logar') });
    let notasFiscais = await ApiNotasFiscais.get({pagina: 1});

    await this.setState({
      isLoaded: true,
      userLogadoInfo,
      notasFiscais
    });
  }

  render() {
    return html(this);
  }
}

