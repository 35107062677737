import { Component } from 'react';
import html from './pedidos.jsx';
import { browserHistory } from 'react-router'
import ApiUser from '../../../api/user.js';
import ApiPedidos from '../../../api/pedidos.js';
import ApiSituacoes from '../../../api/situacoes.js';
import $ from 'jquery';


export default class PagePainelPedidos extends Component {
  constructor(props) {
    super(props);
    this.formSubmitFiltro = this.formSubmitFiltro.bind(this, this);
    this.state = {
      json: null,
      erro: null,
      isLoaded: false,
      todosFiltros: "display-none",
      situacaoSelecionada: "Em aberto"
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);

    let userLogadoInfo = await ApiUser.getDataUserLogado(() => { }, () => { browserHistory.push('/logar') });
    let pedidos = await ApiPedidos.get({ pagina: 1 }, "idSituacao[6]");
    let situacoesVendas = await ApiSituacoes.getVendas();
    let filtroSituacoes = ["em aberto", "em andamento", "atendido", "cancelado",];

    await this.setState({
      isLoaded: true,
      pedidos,
      userLogadoInfo,
      situacoesVendas,
      filtroSituacoes
    });

  }

  ocultarAparecerFiltros() {
    if (this.state.todosFiltros === "") {
      this.setState({ todosFiltros: "display-none" });
      $(".btn-filtros").addClass("btn-styleblinging");
      $(".btn-filtros").removeClass("btn-default");
    } else {
      this.setState({ todosFiltros: "" });
      $(".btn-filtros").removeClass("btn-styleblinging");
      $(".btn-filtros").addClass("btn-default");
    }
  }


  formSubmitFiltro(shelf, e) {
    e.preventDefault();
    let camposForm = {};
    let formData = new FormData(e.target);
    formData.forEach((valor, chave) => {
      camposForm[chave] = valor;
    });
    let situacaoSelecionada = $(`.todosFiltros form select[name="idSituacao"] [value="${camposForm.idSituacao}"]`).text();
    
    shelf.setState({ isLoaded: false, erro: null });

    if (camposForm.numeroPedido) {
      window.location.href = `/painel/pedido/${camposForm.numeroPedido}`
    } else {
      ApiPedidos.get({ pagina: 1 }, `idSituacao[${camposForm.idSituacao}]`).then(function (res) {

        if (String(res) === "undefined") {
          shelf.setState({ isLoaded: true, pedidos: [], situacaoSelecionada });
        }else{
          shelf.setState({ isLoaded: true, pedidos: res, situacaoSelecionada });
        }

      }).catch(function (error) {
        shelf.setState({ erro: "" + error });
        return;
      });
    }
  }

  clickTagFiltroSituacao(idSituacao){
    $(`.todosFiltros form select[name="idSituacao"]`).val(idSituacao);
    $(`.todosFiltros form select[name="idSituacao"]`).find(`option[value="${idSituacao}"]`).attr('selected','selected');
    $(`.todosFiltros form button[type="submit"]`).click();
  }

  render() {
    return html(this);
  }
}

