import React, { Component } from 'react';
import { Link } from 'react-router';
import Cookies from 'js-cookie';
import LayoutCarregando from '../../carregando/carregando';
import * as Fun from '../../../../../assets/js/fun';

export default class LayoutMenuMobileBaixo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      json: null,
      erro: null,
      isLoaded: false
    };
  }


  async componentDidMount() {
    let userLogado = await Cookies.get("userLogado");

    await this.setState({ userLogado, isLoaded: true });
  }

  render() {
    if (!this.state.isLoaded) {
      return (<LayoutCarregando></LayoutCarregando>);
    } else {
      return (
        <div id="navbar" className="navbar-collapse" remove-pc="">
          <ul className="nav navbar-right center">

            <li>
              <Link to="/painel">
                <i className="button fal fa-home fa-2x fa-mobile-4x" aria-hidden="true"></i>
                <p>Início</p>
              </Link>
            </li>

            {
              (String(this.state.userLogado) === "true") ? (<>
                <li>
                  <Link to="/painel/notas/fiscais">
                    <i className="button fal fa-receipt fa-2x fa-mobile-4x" aria-hidden="true"></i>
                    <p>Notas</p>
                  </Link>
                </li>

                <li className="destaque">
                  <Link to="/painel/pedidos">
                    <i className="button fal fa-truck-loading fa-2x fa-mobile-3x" aria-hidden="true"></i>
                    <p>Pedidos</p>
                  </Link>
                </li>

                <li>
                  <Link to="/painel/produtos">
                    <i className="button fal fa-box-full fa-2x fa-mobile-4x" aria-hidden="true"></i>
                    <p>Produtos</p>
                  </Link>
                </li>

                <li>
                  {/* eslint-disable-next-line */}
                  <a href="#" onClick={() => { Fun.abrirNotificao("Essa parte do sistema ainda não está pronta.", "") }}>
                    <i className="button fal fa-user-circle fa-2x fa-mobile-4x" aria-hidden="true"></i>
                    <p>Conta</p>
                  </a>
                </li>
              </>) : (
                <li>
                  <Link to="/logar">
                    <i className="fal fa-sign-in fa-2x fa-mobile-4x" aria-hidden="true"></i>
                    <p>Logar-se</p>
                  </Link>
                </li>
              )
            }

          </ul>
        </div>
      )
    }
  }
}

