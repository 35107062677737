import { Component } from 'react';
import html from './home.jsx';
import $ from 'jquery';
import ApiUser from '../../api/user.js';
import { browserHistory } from 'react-router'

export default class PageHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      json: null,
      erro: null,
      isLoaded: false,
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    await ApiUser.getDataUserLogado(()=>{browserHistory.push('/painel') });
    await this.setState({
      isLoaded: true
    });
    $("#carousel-tutorial-home").carousel({ interval: 0 });
    this.removerBotaoProximoLogar();
    this.pularSlideTutorial();
  }

  pularSlideTutorial(){
    if (this.props.location.pathname === "/tutorial/bling/chave/api") {
      $("#carousel-tutorial-home").carousel("next");
    }
  }

  removerBotaoProximoLogar() {
    $("#carousel-tutorial-home").on('slid.bs.carousel', function () {
      let indicadorAtivo = $('#carousel-tutorial-home .carousel-indicators li.active');
      let posicaoAtual = parseInt(indicadorAtivo.data('slideTo'));
      if (posicaoAtual >= 2) {
        let html = `
          <a class="btn btn-styleblinging btn-lg" href="/logar">
            Fazer Login
          </a>
        `;
        $("#carousel-tutorial-home .carousel-btns").html(html);
      }
    });
  }

  async scrollToBottom(id) {
    document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
  }

  render() {
    return html(this);
  }
}

