import React, { Component } from 'react';
import { Link } from 'react-router';
import config from '../../../../../config';
import LayoutMenuLateral from '../lateral';

export default class LayoutMenuMobileCima extends Component {

  verificarRota(p) {
    if (p === "/") {
      return false;
    } else {
      return true;
    }
  }

  render() {
    let props = this.props.props.props;
    let pathName = props.location.pathname;
    let pageName;
    try {
      pageName = props.routes[1].name;
    } catch (error) {
      try {
        pageName = props.routes[0].name;
      } catch (error) {
        pageName = "Erro: Pagina sem nome.";
      }
    }
    localStorage.setItem('pageName', pageName);
    document.title = pageName + " | " + config.site.titulo;


    if (this.verificarRota(pathName)) {

      return (
        <div>

          {/* Navbar logo quando está no PC */}
          <div className="navbar-header" remove-mobile="">
            <Link to="/">
              <img src="/assets/img/logotipo_branca_pc.png" className="logo-pc img-responsive" alt="Logo do aplicativo." remove-mobile="" />
            </Link>
          </div>

          {/* Navbar logo quando está no Mobile */}
          <div className="navbar-header" remove-pc="">
            <button className="navbar-toggle navbar-toggle-left" onClick={props.router.goBack.bind(null)}>
              <i className="fal fa-chevron-left fa-2x" aria-hidden="true"></i>
            </button>
            <button className="navbar-toggle navbar-toggle-right" onClick={LayoutMenuLateral.prototype.openNav}>
              <i className="fal fa-bars fa-2x" aria-hidden="true"></i>
            </button>
            <h3 className="navbar-pageName center-text">{pageName}</h3>
          </div>

        </div>
      )

    } else {

      return (
        <div>
          <div className="navbar-header" remove-mobile="">
            <a href="/">
              <img src="/assets/img/logotipo_branca_pc.png" className="logo-pc img-responsive" alt="Logo do aplicativo." remove-mobile="" />
            </a>
          </div>

          <div className="navbar-header" remove-pc="">
            {/* <button className="navbar-toggle navbar-toggle-left">
              <i className="fal fa-bell fa-2x" aria-hidden="true"></i>
            </button> */}
            <button className="navbar-toggle navbar-toggle-right" onClick={LayoutMenuLateral.prototype.openNav}>
              <i className="fal fa-bars fa-2x" aria-hidden="true"></i>
            </button>
            <a href="/">
              <img src="/assets/img/logotipo_branca_mobile.png" className="logo-mobile img-responsive center-img" alt="Logo do aplicativo." remove-pc="" />
            </a>
          </div>
        </div>
      )

    }
  }
}
