import React from 'react';
import LayoutError from '../error/error';
import LayoutCarregando from '../carregando/carregando';
import { Component } from 'react';
import { Link } from 'react-router';

export default class LayoutObjetosPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            json: null,
            erro: null,
            isLoaded: false
        };
    }

    async componentDidMount() {
        await this.setState({
            isLoaded: true
        });
    }

    render() {
        if (this.state.erro != null) {
            return (<LayoutError erro={this.state.erro}></LayoutError>);
        } else if (!this.state.isLoaded) {
            return (<LayoutCarregando></LayoutCarregando>);
        } else {
            return (
                <div className="panel panel-default panel-user-previa" key={this.props.key}>
                    <div className={"panel-body " + this.props.className} style={this.props.style}>
                        {
                            this.props.link ? (
                                <Link to={this.props.link}>
                                    {this.props.children}
                                </Link>
                            ) : (
                                <>
                                    {this.props.children}
                                </>
                            )
                        }
                    </div>
                </div>
            )
        }
    }
}