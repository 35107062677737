const config = {
  site: {
    titulo: "StyleBlinging",
    fraseEfeito: "App belo e prático para gerenciamento de pedidos do Bling ERP"
  },
  api:{
    brasilApi:{
      baseUrl: `https://brasilapi.com.br/api`
    },
    bling:{
      baseUrl: `https://bling.com.br/Api/v2`
    },
    corsAnywhere:{
      baseUrl: "https://cors.proxy.ecossistema.dev/"
    }
  },
  multiLojas:{
    linkPedido:{
      shopee: "https://seller.shopee.com.br/portal/sale/order?search=[ID]",
      b2w: "https://www.b2wmarketplace.com.br/v3/pedidos/detalhes/[ID]",
      skyhub: "https://in.skyhub.com.br/orders/[ID]",
      mercadolivre: "https://www.mercadolivre.com.br/vendas/[ID]/detalhe"
    }
  }
}

export default config;