import React, { Component } from 'react';

export default class Page404 extends Component {
  render() {
    return (
      <div className="bg">
        <div className="container animated fadeIn slow">
          <div className="row align-items-center">
            <div className="col">
              <div className="form-signin modal-login">
                <div className="responsive-size">
                  <h1 className="titulo-404">404</h1>
                  <p className="texto-404">Pagina não encontrada !!!</p>
                  <button onClick={()=>{window.location.href="/"}} className="btn btn-default btn-lg btn-block btn-block-mobile" > Ir ao início! </button>
                </div>
              </div>
            </div>
          </div>
        </div></div>
    )
  }
}