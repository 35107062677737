import { Component } from 'react';
import { request } from '../../assets/js/fun';
import config from '../../config';

export default class ApiSituacoes extends Component {

    static async getVendas() {
        return new Promise(async (sucesso, rejeicao) => {
            let url = config.api.corsAnywhere.baseUrl + config.api.bling.baseUrl;
            url += `/situacao/vendas/json/`;
            url += `?apikey=${localStorage.getItem("chaveApiBling")}`;

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            request(url, requestOptions).then(async (response) => {
                let situacoes = response.retorno.situacoes;
                if (situacoes) {
                    situacoes.sort((a, b) => {
                        return parseInt(b.situacao.id) - parseInt(a.situacao.id);
                    });
                }
                sucesso(situacoes);
            }).catch(error => {
                rejeicao(error);
            });

        });
    }

    static async getByName(name) {
        return new Promise(async (sucesso, rejeicao) => {

            let situacaoDados = await this.getVendas();
            situacaoDados = situacaoDados.filter(function (e) {
                return String(e.situacao?.nome) === String(name);
            });

            situacaoDados = situacaoDados[0]?.situacao;
            sucesso(situacaoDados);
        });
    }

}
