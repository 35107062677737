import React from 'react';
import LayoutError from '../error/error';
import LayoutCarregando from '../carregando/carregando';
import { Component } from 'react';
import LayoutObjetosPanel from '../panel/panel';

export default class LayoutObjetosPanelnotafiscal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            json: null,
            erro: null,
            isLoaded: false
        };
    }

    async componentDidMount() {
        let notaData = String(this.props.notafiscal.dataEmissao).split("-");

        await this.setState({
            isLoaded: true,
            notaData: {
                ano: notaData[0],
                mes: notaData[1],
                dia: notaData[2].split(" ")[0]
            },
        });
    }

    render() {
        if (this.state.erro != null) {
            return (<LayoutObjetosPanel className="text-left"><LayoutError erro={this.state.erro} style={{ marginTop: "0px" }}></LayoutError></LayoutObjetosPanel>);
        } else if (!this.state.isLoaded) {
            return (<LayoutObjetosPanel className="text-left"><LayoutCarregando style={{ marginTop: "0px" }}></LayoutCarregando></LayoutObjetosPanel>);
        } else {
            return (
                <LayoutObjetosPanel className="text-left panelnotafiscal" key={this.props.key}>
                    <div className="row text-left">
                        <div className="col-xs-3">
                            <span className="numero"><b>#{this.props.notafiscal?.numero}</b></span><br />
                            <span className="integracao"><b>{this.props.notafiscal?.tipoIntegracao || this.props.notafiscal?.transporte?.transportadora}</b></span><br />
                            <span className="data"><b>{this.state.notaData?.dia}/{this.state.notaData?.mes}/{this.state.notaData?.ano}</b></span>
                        </div>
                        <div className="col-xs-4">
                            <span className="nome"><b>{this.props.notafiscal?.cliente?.nome}</b></span><br />
                        </div>
                        <div className="col-xs-5 text-right">
                            <span className="label label-default">{this.props.notafiscal?.situacao}</span><br />
                            {
                                this.props.notafiscal.linkDanfe && (
                                    <a href={this.props.notafiscal.linkDanfe} className="linkIconMargim" title="Ver nota físcal DANFE" target="_blank" rel="noopener noreferrer">
                                        <i class="fad fa-eye fa-2x"></i>
                                    </a>
                                )
                            }
                            {
                                this.props.notafiscal.xml && (
                                    <a href={this.props.notafiscal.xml} className="linkIconMargim" title="Ver XML da nota físcal" target="_blank" rel="noopener noreferrer">
                                        <i class="fad fa-file-code fa-2x"></i>
                                    </a>
                                )
                            }
                        </div>
                    </div>
                </LayoutObjetosPanel>
            )
        }
    }
}