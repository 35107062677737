import React from 'react';
import LayoutError from '../../layout/objetos/error/error';
import LayoutCarregando from '../../layout/objetos/carregando/carregando';
import LayoutObjetosPanel from '../../layout/objetos/panel/panel';

export default function html(shelf) {

    if (shelf.state.erro != null) {
        return (<LayoutError erro={shelf.state.erro}></LayoutError>);
    } else if (!shelf.state.isLoaded) {
        return (<LayoutCarregando></LayoutCarregando>);
    } else {

        // API carregada com sucesso pagina oficial
        return (
            <div className="container text-center">
                <h1 className="remove-mobile ">{localStorage.getItem("pageName")}</h1>
                <br className="remove-mobile" />

                <span><b>Dados do mês <input className="input-mes-dados" type="month" defaultValue={shelf.state.mesAnoDados} onChange={shelf.setMesAtualDados}/></b></span>
                <hr/>

                <div className="row">

                    <div className="col-xs-6 col-md-3">
                        <LayoutObjetosPanel className="text-left" link="painel/pedidos">
                            <h3><i className="text-color-principal fad fa-truck-loading"></i> {shelf.state.estatisticas?.numeroPedidos}</h3>
                            <span><b>Pedidos</b></span><br/>
                        </LayoutObjetosPanel>
                    </div>

                    <div className="col-xs-6 col-md-3">
                        <LayoutObjetosPanel className="text-left">
                            <h3><i className="text-color-principal fad fa-sack-dollar"></i> {parseFloat(shelf.state.estatisticas?.valorPedidos).toLocaleString('pt-br', { style: "currency", currency: "BRL" }).split(",")[0]}</h3>
                            <span><b>Vendas</b></span><br/>
                        </LayoutObjetosPanel>
                    </div>

                    <div className="col-xs-6 col-md-3">
                        <LayoutObjetosPanel className="text-left">
                            <h3><i className="text-color-principal fad fa-box-full"></i> {shelf.state.estatisticas?.quantidadeProdutos}</h3>
                            <span><b>Produtos vendidos</b></span><br/>
                        </LayoutObjetosPanel>
                    </div>

                    <div className="col-xs-6 col-md-3">
                        <LayoutObjetosPanel className="text-left">
                            <h3><i className="text-color-principal fad fa-badge-percent"></i> {parseFloat(shelf.state.estatisticas?.valorDescontoPedidos).toLocaleString('pt-br', { style: "currency", currency: "BRL" }).split(",")[0]}</h3>
                            <span><b>Descontos</b></span><br/>
                        </LayoutObjetosPanel>
                    </div>

                </div>

            </div>
        )

    }
}