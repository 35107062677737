import React from 'react';
import LayoutError from '../../../layout/objetos/error/error';
import LayoutCarregando from '../../../layout/objetos/carregando/carregando';
import LayoutObjetosPanelPedidos from '../../../layout/objetos/panelPedidos/panelPedidos';

export default function html(shelf) {

    if (shelf.state.erro != null) {
        return (<LayoutError erro={shelf.state.erro}></LayoutError>);
    } else if (!shelf.state.isLoaded) {
        return (<LayoutCarregando></LayoutCarregando>);
    } else {

        // API carregada com sucesso pagina oficial
        return (
            <div className="container text-center animated fadeIn slow">
                <h1 className="remove-mobile ">{localStorage.getItem("pageName")}</h1>
                <br className="remove-mobile" />

                <div className="filtros">
                    <div className="demais pull-right">
                        <button type="button" title="Abrir filtros" className="btn btn-styleblinging btn-filtros" onClick={() => { shelf.ocultarAparecerFiltros() }}>
                            <i class="fa fa-filter"></i>
                            </button>
                    </div>

                    <div className="tags text-left">
                        {shelf.state.situacoesVendas.map(situacao => (<>
                            {shelf.state.filtroSituacoes.includes(String(situacao?.situacao?.nome).toLocaleLowerCase()) && (
                                <span class="label label-default tags-filtro cursosLink"
                                    onClick={() => { shelf.clickTagFiltroSituacao(situacao?.situacao?.id) }}
                                    style={{ backgroundColor: situacao?.situacao?.cor }}
                                    title={`Clique para filtrar pedido com a situação ${situacao?.situacao?.nome}`}
                                >
                                    {situacao?.situacao?.nome}
                                </span>
                            )}
                        </>))}
                    </div>

                    <div className={`todosFiltros ${shelf.state.todosFiltros}`}>
                        <form onSubmit={shelf.formSubmitFiltro}>
                            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <input name="numeroPedido" className="form-control" type="number" placeholder="Filtrar pelo número" min="0" />
                            </div>

                            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <select name="idSituacao" className={`form-control`} >
                                    <option value="" selected disabled>Filtrar pela situação</option>
                                    {shelf.state.situacoesVendas.map(situacao => (<>
                                        <option value={situacao?.situacao?.id}>{situacao?.situacao?.nome}</option>
                                    </>))}
                                </select>
                            </div>
                            <button type="submit" className="btn btn-styleblinging btn-block" >Filtrar</button>

                        </form>
                    </div>

                </div>

                <div className="pedidos">

                    {
                        shelf.state?.pedidos?.length > 1 ? (<>
                            {
                                shelf.state.pedidos.map(pedido => (
                                    <LayoutObjetosPanelPedidos
                                        key={pedido.pedido.numero}
                                        pedido={pedido.pedido}
                                        situacoes={shelf.state.situacoesVendas}
                                    />
                                ))
                            }
                        </>) : (<>
                            <h1>Sem pedidos na situação {shelf.state.situacaoSelecionada}.</h1>
                        </>)
                    }


                </div>
            </div>
        )

    }
}