import React, { Component } from 'react';
import LayoutMenuLateral from './lateral';
import LayoutMenuMobileCima from './mobile/cima';
import LayoutMenuMobileBaixo from './mobile/baixo';
import LayoutMenuDesktopCima from './desktop/cima';

export default class LayoutMenu extends Component {

  render() {
    return (
      <div>

        <LayoutMenuLateral />

        <nav className="navbar navbar-gradient navbar-top scrolled navbar-fixed-top">
          <div className="container">
            <LayoutMenuMobileCima props={this.props} />
            <LayoutMenuDesktopCima />
            <LayoutMenuMobileBaixo />
          </div>
        </nav >
      </div>
    )
  }
}

