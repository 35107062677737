import { Component } from 'react';
import html from './pedido.jsx';
import { browserHistory } from 'react-router'
import ApiUser from '../../../api/user.js';
import ApiPedidos from '../../../api/pedidos.js';
import ApiSituacoes from '../../../api/situacoes.js';
import config from '../../../../config.js';
import ApiBrasil from '../../../api/brasilApi.js';
import ApiProdutos from '../../../api/produtos.js';


export default class PagePainelPedido extends Component {
  constructor(props) {
    super(props);
    this.state = {
      json: null,
      erro: null,
      isLoaded: false
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);

    let numeroPedido = this.props.params.numeroPedido;
    let userLogadoInfo = await ApiUser.getDataUserLogado(() => { }, () => { browserHistory.push('/logar') });
    let pedido = await ApiPedidos.getByID({ numero: numeroPedido });
    let pedidoData = String(pedido.data).split("-");
    let situacaoDados = await ApiSituacoes.getByName(pedido?.situacao);
    let consultaCEP = await ApiBrasil.get({cep: pedido?.transporte?.enderecoEntrega?.cep});
    let produtosDetalhes = [];
    for (let index = 0; index < pedido?.itens.length; index++) {
      const i = pedido?.itens?.[index];
      const detalhesProduto = await ApiProdutos.getByCodigo({codigo: i?.item?.codigo});
      pedido.itens[index].item.imagem = detalhesProduto?.produto?.imagem?.[0]?.link;
      produtosDetalhes.push(detalhesProduto);
    }

    if (!pedido?.numero) {
      await this.setState({
        erro: "Não encontramos o pedido no bling, tente voltar na pagina de pedidos e clicar no pedido novamente.",
        isLoaded: true
      })
      return;
    }

    await this.setState({
      isLoaded: true,
      pedido,
      userLogadoInfo,
      situacaoDados,
      consultaCEP,
      produtosDetalhes,
      pedidoData: {
          ano: pedidoData[0],
          mes: pedidoData[1],
          dia: pedidoData[2]
      },
      linkPedidoLoja: config?.multiLojas?.linkPedido?.[pedido?.tipoIntegracao?.toLowerCase()]
    });

    console.log(this.state);

  }

  render() {
    return html(this);
  }
}

