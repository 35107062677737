import React from 'react';
import LayoutError from '../error/error';
import LayoutCarregando from '../carregando/carregando';
import { Component } from 'react';
import LayoutObjetosPanel from '../panel/panel';

export default class LayoutObjetosPanelProdutos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            json: null,
            erro: null,
            isLoaded: false
        };
    }

    async componentDidMount() {
        await this.setState({
            isLoaded: true,
        });
    }

    render() {
        if (this.state.erro != null) {
            return (<LayoutObjetosPanel className="text-left"><LayoutError erro={this.state.erro} style={{ marginTop: "0px" }}></LayoutError></LayoutObjetosPanel>);
        } else if (!this.state.isLoaded) {
            return (<LayoutObjetosPanel className="text-left"><LayoutCarregando style={{ marginTop: "0px" }}></LayoutCarregando></LayoutObjetosPanel>);
        } else {
            return (
                <LayoutObjetosPanel className="text-left panelProduto" key={this.props.key}>
                    <div className="row text-left">
                        <div className="col-xs-5 col-sm-3 col-md-2 center-text">
                            <span className="sku">SKU: <b>{this.props.produto?.codigo}</b></span><br />
                            {this.props.produto?.imagem?.[0] && (
                                <img src={this.props.produto?.imagem?.[0]?.link} className="imgProduto img-responsive" alt={`Foto do produto`}></img>
                            )}
                        </div>
                        <div className="col-xs-7 col-sm-9 col-md-10">
                            <span className="nome">{this.props.produto?.descricao}</span><br />
                            <span className="preco"><b>{parseFloat(this.props.produto?.preco).toLocaleString('pt-br', { style: "currency", currency: "BRL" })}</b></span><br />
                            <span className="estoque">
                                {
                                    this.props.produto?.depositos.map(e => (<>
                                        {
                                            parseInt(e?.deposito?.saldo) !== 0 && (
                                                <span className="label label-default labelEstoqueProduto">{e?.deposito?.nome}: {parseInt(e?.deposito?.saldo) || 0}</span>
                                            )
                                        }
                                    </>))
                                }
                            </span><br />
                        </div>
                    </div>
                </LayoutObjetosPanel>
            )
        }
    }
}